import {get, post, put} from "@/api/axios";

export const list = params => get('/web/agent/manage/list', params)

/**
 * /web/agent/manage/state/update put
 */
export const stateUpdate = params => put('/web/agent/manage/state/update', params)

/**
 * /web/agent/manage/look/list get  经纪人管理-带看记录-列表
 */
export const lookList = params => get('/web/agent/manage/look/list', params)

/**
 * /web/agent/manage/customer/list get 经纪人管理-客户列表-列表
 */
export const customerList = params => get('/web/agent/manage/customer/list', params)

/**
 * /web/agent/manage/comment/list get 经纪人管理-评价记录-列表
 */
export const commentList = params => get('/web/agent/manage/comment/list', params)


export const remarkUpdate = params => put('/web/agent/manage/remark/update',params)

//虚拟合伙人
export const virtualNumberUpdate = params => put('/web/agent/manage/invent/phone/update',params)

export const listCity = params => post('/web/agent/manage/list/city',params)