<template>
  <div>
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20 flex-wrap">
          <el-input size="medium" v-model="searchData.username" :maxlength="100" placeholder="用户姓名" clearable class="marginRight10 width300"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginRight10">搜索</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { customerList } from "@/api/agent/manage"
export default {
  name: "recordTwo",
  data(){
    return{
      searchData: {
        username: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menu: false, // 显示操作栏
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '备注（称呼）',
            prop: 'username'
          },
          {
            label: '客户ID',
            prop: 'userCode'
          },
          {
            label: '手机号',
            prop: 'phone'
          },
          {
            label: '选择客分组',
            prop: 'type',
            type: 'radio',
            dicData: [
              {
                label: '成交客户',
                value: 1
              },
              {
                label: '带看',
                value: 2
              },
              {
                label: '咨询',
                value: 3
              }
            ]
          },
          {
            label: '客户描摹',
            prop: 'customerDesc'
          }
        ]
      },
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      customerList({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },

    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        username: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
  }
}
</script>

<style scoped>

</style>