<template>
  <div class="main-content">
    <el-tabs v-model="activeName">
      <el-tab-pane label="带看记录" name="first">
        <RecordOne />
      </el-tab-pane>
      <el-tab-pane label="客户列表" name="second">
        <RecordTwo />
      </el-tab-pane>
      <el-tab-pane label="评价记录" name="third">
        <RecordThree />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RecordOne from "./components/recordOne"
import RecordTwo from "./components/recordTwo"
import RecordThree from "./components/recordThree"
export default {
  name: "info",
  components: {
    RecordOne,
    RecordTwo,
    RecordThree
  },
  data(){
    return{
      activeName: 'first'
    }
  }
}
</script>

<style scoped>

</style>