<template>
  <div>
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20 flex-wrap">
          <el-input size="medium" v-model="searchData.username" :maxlength="100" placeholder="用户姓名" clearable class="marginRight10 width300"/>
          <el-input size="medium" v-model="searchData.code" :maxlength="100" placeholder="房源号" clearable class="marginRight10 width300"/>
          <div class="width200 marginRight10">
            <el-select v-model="searchData.state" clearable>
              <el-option label="带看状态" value=""></el-option>
              <el-option label="未带看" :value="1"></el-option>
              <el-option label="带看中" :value="2"></el-option>
              <el-option label="已带看" :value="3"></el-option>
              <el-option label="已取消" :value="4"></el-option>
            </el-select>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginRight10">搜索</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
        </div>
      </template>

    </avue-crud>
  </div>
</template>

<script>
import { lookList } from "@/api/agent/manage"
export default {
  name: "recordOne",
  data(){
    return{
      searchData: {
        username: '',
        code: '',
        state: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menu: false, // 显示操作栏
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '带看时间',
            prop: 'subscribeTime'
          },
          {
            label: '业务类型',
            prop: 'type',
            type: 'radio',
            dicData: [
              {
                label: '二手房交易',
                value: 1
              },
              {
                label: '房屋租赁',
                value: 2
              }
            ]
          },
          {
            label: '房源号',
            prop: 'code'
          },
          {
            label: '房源标题',
            prop: 'title'
          },
          {
            label: '用户姓名',
            prop: 'username'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户ID',
            prop: 'userCode'
          },
          {
            label: '带看状态',
            prop: 'state',
            type: 'radio',
            dicData: [
              {
                label: '未带看',
                value: 1
              },
              {
                label: '带看中',
                value: 2
              },
              {
                label: '已带看',
                value: 3
              },
              {
                label: '已取消',
                value: 4
              }
            ]
          }
        ]
      },
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      lookList({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        username: '',
        code: '',
        state: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
  }
}
</script>

<style scoped>

</style>