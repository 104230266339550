<template>
  <div>
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20 flex-wrap">
          <el-input size="medium" v-model="searchData.nickname" :maxlength="100" placeholder="用户姓名" clearable class="marginRight10 width300"/>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" placeholder="用户手机号" clearable class="marginRight10 width300"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginRight10">搜索</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { commentList } from "@/api/agent/manage"
export default {
  name: "recordThree",
  data(){
    return{
      searchData: {
        nickname: '',
        phone: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menu: false, // 显示操作栏
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '评价时间',
            prop: 'createTime'
          },
          {
            label: '用户姓名',
            prop: 'nickname'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户ID',
            prop: 'userCode'
          },
          {
            label: '房源号',
            prop: 'houseCode'
          },
          {
            label: '总体满意度',
            prop: 'satisfiedNum'
          },
          {
            label: '响应速度',
            prop: 'speedNum'
          },
          {
            label: '服务质量',
            prop: 'qualityNum'
          },
          {
            label: '服务态度',
            prop: 'attitudeNum'
          },
          {
            label: '专业度',
            prop: 'majorNum'
          },
          {
            label: '评价内容',
            prop: 'content'
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      commentList({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        nickname: '',
        phone: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
  }
}
</script>

<style scoped>

</style>